import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { ProgramService } from 'src/app/shared/services/program.service';
import { WorkForceService } from 'src/app/shared/services/work-force.service';
import { HttpEventType } from "@angular/common/http";
import { NgxSpinnerService } from 'ngx-spinner';
import { DeletePopupComponent } from 'src/app/shared/components/delete-popup/delete-popup.component';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers:[DatePipe]
  // encapsulation:ViewEncapsulation.None
})
export class ListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  krForm!: FormGroup

  selectedResourceFile: any;
  sortRecords: any = 10;
  showFilter: boolean = false;
  clearcarousel: any;
  portFolioId: any;
  SubPortFolioId: any;
  projectID: any;
  resourceName: any;
  ResourceTitleId: any;
  ResourceClassificationId: any;
  ResourcePyramidId: any;
  gdpId: any;
  programId:any;

  workForceTableList: any[] = [];
  workForceTableTotalList: any[] = [];
  CarouselFilterList: any[] = [];
  subportfolio: any[] = [];
  projectList: any[] = [];
  resourceNameList: any[] = [];
  resourceTitleList: any[] = [];
  resourceClassificationList: any[] = [];
  resourcePyramidList: any[] = [];
  GdpList: any[] = [];

  filterSubPortfoliolist: any;
  filterProjectList: any;
  filterResourceTitleList: any;
  filterResourceNameList: any;
  filterResourceClassificationList: any;
  filterResourcePrymaidList: any;
  filterGdpList: any;
  progress: any;
  uploadedFileSize: any;

  CurrentPageList: any[] = [];
  sortLetters: any[] = [];
  SortedList: any[] = [];
  allocationList: any[] = [];
  errorLogsList: any;

  CurrentPage: any = 0;
  recordsTotalCount: any;
  displayRecordsCount:any
  EndValue: any = 10;
  startValue: any = 0;
  showChartLegends: boolean = true;
  selctedLetterIndex: number | any =-1;

  allocationListNew:any[]=[];
  insightToDelete:any;

  @ViewChild('showErrorLogTable', { static: false }) showErrorLogTable: TemplateRef<any> | any;
  insightsList: any[]=[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private workForceService: WorkForceService,
    private toastrService: ToastrService,
    private ProgramService: ProgramService,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private csvService : DataToCsvService,
    private _formValidator:FormValidatorService,
    private date:DatePipe
  ) { }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.creatforms();
    this.getAllInsights();


  }

  creatforms() {
    this.krForm = this.formBuilder.group({
     insight: ['',Validators.required],
      description: ['',Validators.required],
      link: ['',Validators.required]
    });
  }

  showAddInsights(modal:any){
    
   this.dialog.open(modal, {panelClass:"mw-650"}); 

  }

  deletePopUp(modal:any,data:any){
    this.insightToDelete = data;
    this.dialog.open(modal, {panelClass:"mw-650"}); 


  }
  OpenLink(link:any){
    window.open(link, "_blank");

  }
  validateAndSubmit(){
    if(this.krForm?.valid){
      this.spinner.show()
      let payload= {"insight" : this.krForm?.get('insight')?.value,
                    "description":this.krForm?.get('description')?.value,
                    "report_link" : this.krForm?.get('link')?.value
                  }

      this.workForceService.saveNewInsightsData(payload).subscribe((response:any)=>{
        this.spinner.hide()
        this.toastrService.success("Insight saved successfully.");
        this.getAllInsights();
        this.dialog.closeAll();


      },err=>{
        this.toastrService.error("Failed to save insight.");
        this.spinner.hide()

 
      })            

    }else{
      this.toastrService.error("Please fill all required Field.");
    }
  }

  getAllInsights(){
    this.workForceService.getInsightsList().subscribe((response:any)=>{
      this.spinner.hide()
      this.insightsList = response;


    },err=>{
      this.spinner.hide()
      this.toastrService.error("Failed to get insights.");


    }) 
  }
  deleteInsight(){
    this.spinner.show();
    this.workForceService.deleteInsightData(this.insightToDelete?.id).subscribe((response:any)=>{
      this.spinner.hide()
      this.toastrService.success("Insight deleted successfully.");
      this.getAllInsights();
      this.dialog.closeAll();


    },err=>{
      this.spinner.hide()
      this.toastrService.error("Failed to get insights.");
      this.dialog.closeAll();



    })  
  }
 

}
