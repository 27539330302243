<div class="workForce">
    <header class="workForce-Header">
        <div class="w-100 bg-lavendar mt-80">
            <app-header></app-header>
        </div>
        <div class="d-flex flex-column">
            <div class="bg-cont-overlay"></div>
        </div>
    </header>

    <div class="workForce-body container-fluid container-xxl margin-minus-50">
        <router-outlet></router-outlet>
    </div>

    <footer class="workForce-Footer">
        <div class="container pt-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-muted">
                        <p align="center">Copyright © {{currentYear}} {{staticText.footer.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>