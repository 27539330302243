import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PowerBiRoutingModule } from './power-bi-routing.module';
import { ListComponent } from './list/list.component';
import { ViewComponent } from './view/view.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';


@NgModule({
  declarations: [
    ListComponent,
    ViewComponent,
    IndexComponent
  ],
  imports: [
    CommonModule,
    PowerBiRoutingModule,
    SharedModule,
    NgxPermissionsModule,
    MaterialmodulesModule,
    NgbModule
  ]
})
export class PowerBiFlowModule { }
