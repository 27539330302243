import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { WorkForceService } from 'src/app/shared/services/work-force.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class ViewComponent implements OnInit {

  staticText: any = (textConfiguration as any).default;

  section1: boolean = true;
  section2: boolean = false;
  section3: boolean = false;
  section4: boolean = false;
  showEditPageContent: boolean = false;
  workForceData: any;
  workForceId: any;
  gdpId: any;
  portFolioId: any;

  workForceEditForm!: FormGroup;


  resourceClassificationList: any[] = [];
  resourcePyramidList: any[] = [];
  portFolioList: any[] = [];
  subPortFolioList: any[] = [];

  filterPortfolioList: any;
  filterSubPortfolioList: any;
  filterGdpList: any;
  filterStateList: any;
  filterCountryList: any;
  filterJobTitleList: any;
  filterJobCategoryList: any;
  filterResourcePyramidList: any;
  filterOffShoreList: any;
  filterContractList: any;
  filterResourceClassificationList: any;
  filterProjectIdList: any;

  gdpList: any[] = [];
  stateList: any[] = [];
  countryList: any[] = [];
  selectedStateList: any[] = [];
  jobList: any[] = [];
  jobCategoryList: any[] = [];
  OffShoreList: any[] = [];
  contractList: any[] = [];
  foundationList: any[] = [];
  projectList: any[] = [];
  btoa:any=btoa

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private workForceService: WorkForceService,
    private ProgramService: ProgramService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private datepipe: DatePipe,
    private _formValidator:FormValidatorService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('edit')) {
          this.showEditPageContent = true;
        } else {
          this.showEditPageContent = false;
        }
      }
    })
  }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    console.log("permissionsService", this.permissionsService);
    this.workForceId = this.activateRoute.snapshot.paramMap.get('id') ? atob(<any>this.activateRoute?.snapshot?.paramMap?.get('id')):this.activateRoute.snapshot.paramMap.get('id');
    
    this.createForm();
    this.getWorkForceById();
    this.getAllFiltersvalues();
    this.filtersList();
  }

  createForm() {
    this.workForceEditForm = this.formBuilder.group({
      // sectionOne
      contingentId: [''],
      pprNumber: [''],
      resourceName: [''],
      Portfolio: ['',[this._formValidator.autocompleteObjectValidator]],
      subPortfolio: ['',[this._formValidator.autocompleteObjectValidator]],
      gdp: ['',[this._formValidator.autocompleteObjectValidator]],
      projectId: ['',[this._formValidator.autocompleteObjectValidator]],
      // sectionTwo
      state: ['',[this._formValidator.autocompleteObjectValidator]],
      country: ['',[this._formValidator.autocompleteObjectValidator]],
      jobTitle: ['',[this._formValidator.autocompleteObjectValidator]],
      jobCategory: ['',[this._formValidator.autocompleteObjectValidator]],
      resourcePyramid: ['',[this._formValidator.autocompleteObjectValidator]],
      yearsExp: [''],
      offShore: ['',[this._formValidator.autocompleteObjectValidator]],
      contractType: ['',[this._formValidator.autocompleteObjectValidator]],
      resourceClassification: ['',[this._formValidator.autocompleteObjectValidator]],
      // sectionThree
      startDate: [''],
      visaExpiryDate: [''],
      newVisa: [''],
      productionEndDate: [''],
      pIIEndDate: [''],
      pciEndDate: [''],
      crossBorderEndDate: [''],
      genericEndDate: [''],
      gdprComplianceEndDate: [''],
      securityTraining: [''],
      foundationStatus: [''],
      // sectionFour
      continuancePlan: [''],
    })
  }

  getWorkForceById() {
    this.spinner.show();
    this.workForceService.getWorkForceDataById(this.workForceId).subscribe((workForceList: any) => {
      this.workForceData = workForceList.find((findData: any) => findData?.id === parseInt(this.workForceId));
      this.patchForm(this.workForceData)
    })
  }
  patchForm(patchArray?: any) {
    this.workForceEditForm.patchValue({
      // sectionOne
      contingentId: patchArray?.contingent_worker_id,
      pprNumber: patchArray?.ppr_number,
      resourceName: patchArray?.resource_name,
      Portfolio: patchArray?.portfolio,
      subPortfolio: patchArray?.sub_portfolio,
      gdp: patchArray?.vendor,
      projectId: patchArray?.project,
      // sectionTwo
      state: patchArray?.state,
      country: patchArray?.country,
      jobTitle: patchArray?.job_title,
      jobCategory: patchArray?.job_category,
      resourcePyramid: patchArray?.resource_pyramid,
      yearsExp: patchArray?.no_of_experience,
      offShore: patchArray?.offshore_leverage,
      contractType: patchArray?.contract_type,
      resourceClassification: patchArray?.resource_classification,
      // sectionThree
      startDate: patchArray?.start_date,
      visaExpiryDate: patchArray?.visa_expiry_date,
      newVisa: patchArray?.new_visa_application_date,
      productionEndDate: patchArray?.prod_access_end_date,
      pIIEndDate: patchArray?.pii_access_end_date,
      pciEndDate: patchArray?.pci_access_end_date,
      crossBorderEndDate: patchArray?.cross_border_data_end_date,
      genericEndDate: patchArray?.generic_id_end_date,
      gdprComplianceEndDate: patchArray?.gdpr_compliance_end_date,
      securityTraining: patchArray?.date_of_completion_of_security,
      foundationStatus: patchArray?.zfp_foundation_status?.id,
      // sectionFour
      continuancePlan: patchArray?.continuance_plan,
    })
    this.spinner.hide();
  }

  getAllFiltersvalues() {
    this.ProgramService.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.portFolioList = res.records.find((findPortFolio: any) => findPortFolio.field_name === "opportunity_portfolio").field_values;
    }, (error: any) => {
      throw error;
    })
    this.subPortFolioList = [];
      this.filtersList()

    this.workForceService.getAllDropDownValues().subscribe((getAlldropDownValues: any) => {
      //----------------SectionOne------------------- 
      this.gdpList = getAlldropDownValues['vendor'];
      //----------------SectionTwo------------------- 
      this.countryList = getAlldropDownValues['country'];
      this.stateList = getAlldropDownValues['country_state'];
      this.jobList = getAlldropDownValues['job_title'];
      this.jobCategoryList = getAlldropDownValues['job_category'];
      this.resourcePyramidList = getAlldropDownValues['resource_pyramid'];
      this.OffShoreList = getAlldropDownValues['offshore_leverage'];
      this.contractList = getAlldropDownValues['contract_type'];
      this.resourceClassificationList = getAlldropDownValues['resource_classification'];
      //----------------SectionThree------------------- 
      this.foundationList = getAlldropDownValues['zfp_foundation_status'];
      this.projectList = getAlldropDownValues['project'];
    })
  }

  SelectedPortFolioValues(id: any) {
    this.workForceEditForm.controls['subPortfolio'].setValue(null);
    this.portFolioId = id?.option?.value?.id;
    this.subPortFolioList = [];
      this.filtersList()
  }

  SelectedCountryValues(value?: any) {
    this.workForceEditForm.controls['state'].setValue(null);
    if (this.stateList)
      this.selectedStateList = this.stateList[value?.option?.value?.display_name] ? this.stateList[value?.option?.value?.display_name] : [];
    this.filtersList();
  }

  submitForm() {
    this.spinner.show();
    let payload = {
      contingent_worker_id: this.workForceEditForm.get('contingentId')?.value,
      ppr_number: this.workForceEditForm.get('pprNumber')?.value,
      resource_name: this.workForceEditForm.get('resourceName')?.value,
      portfolio: this.workForceEditForm.get('Portfolio')?.value?.id,
      sub_portfolio: this.workForceEditForm.get('subPortfolio')?.value?.id,
      vendor: this.workForceEditForm.get('gdp')?.value?.id,
      project: this.workForceEditForm.get('projectId')?.value?.id,
      // sectionTwo
      state: this.workForceEditForm.get('state')?.value?.id,
      country: this.workForceEditForm.get('country')?.value?.id,
      job_title: this.workForceEditForm.get('jobTitle')?.value?.id,
      job_category: this.workForceEditForm.get('jobCategory')?.value?.id,
      resource_pyramid: this.workForceEditForm.get('resourcePyramid')?.value?.id,
      no_of_experience: this.workForceEditForm.get('yearsExp')?.value,
      offshore_leverage: this.workForceEditForm.get('offShore')?.value?.id,
      contract_type: this.workForceEditForm.get('contractType')?.value?.id,
      resource_classification: this.workForceEditForm.get('resourceClassification')?.value?.id,
      // sectionThree
      start_date: this.datepipe.transform(this.workForceEditForm.get('startDate')?.value, 'yyyy-MM-dd'),
      visa_expiry_date: this.datepipe.transform(this.workForceEditForm.get('visaExpiryDate')?.value, 'yyyy-MM-dd'),
      new_visa_application_date: this.datepipe.transform(this.workForceEditForm.get('newVisa')?.value, 'yyyy-MM-dd'),
      prod_access_end_date: this.datepipe.transform(this.workForceEditForm.get('productionEndDate')?.value, 'yyyy-MM-dd'),
      pii_access_end_date: this.datepipe.transform(this.workForceEditForm.get('pIIEndDate')?.value, 'yyyy-MM-dd'),
      pci_access_end_date: this.datepipe.transform(this.workForceEditForm.get('pciEndDate')?.value, 'yyyy-MM-dd'),
      cross_border_data_end_date: this.datepipe.transform(this.workForceEditForm.get('crossBorderEndDate')?.value, 'yyyy-MM-dd'),
      generic_id_end_date: this.datepipe.transform(this.workForceEditForm.get('genericEndDate')?.value, 'yyyy-MM-dd'),
      gdpr_compliance_end_date: this.datepipe.transform(this.workForceEditForm.get('gdprComplianceEndDate')?.value, 'yyyy-MM-dd'),
      date_of_completion_of_security: this.datepipe.transform(this.workForceEditForm.get('securityTraining')?.value, 'yyyy-MM-dd'),
      zfp_foundation_status: this.workForceEditForm.get('foundationStatus')?.value,
      // sectionFour
      continuance_plan: this.workForceEditForm.get('continuancePlan')?.value,
    }
    this.workForceService.postWorkForceById(this.workForceId, payload).subscribe((formSumbitStatus: any) => {
      if (formSumbitStatus.status === 200 || 201) {
        this.toastrService.success('Resource Updated Successfully');
        this.workForceData = formSumbitStatus?.body.find((findData: any) => findData?.id === parseInt(this.workForceId));
        this.patchForm(this.workForceData);
        this.router.navigate([`/workforce/view/${btoa(this.workForceId)}`]);
      }
    })
  }


  filtersList() {
    //---------------------Section One filterList----------------------------------

    this.filterPortfolioList = this.workForceEditForm.get('Portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.portFolioList, 'display_name') : this.portFolioList.slice())
      );

    this.filterSubPortfolioList = this.workForceEditForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.subPortFolioList, 'display_name') : this.subPortFolioList.slice())
      );

    this.filterGdpList = this.workForceEditForm.get('gdp')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.gdpList, 'name') : this.gdpList.slice())
      );

    //---------------------------Section two filterList---------------------------

    this.filterStateList = this.workForceEditForm.get('state')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.selectedStateList, 'display_name') : this.selectedStateList?.slice())
      );

    this.filterCountryList = this.workForceEditForm.get('country')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.countryList, 'display_name') : this.countryList.slice())
      );

    this.filterJobTitleList = this.workForceEditForm.get('jobTitle')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.jobList, 'name') : this.jobList.slice())
      );

    this.filterJobCategoryList = this.workForceEditForm.get('jobCategory')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.jobCategoryList, 'display_name') : this.jobCategoryList.slice())
      );

    this.filterResourcePyramidList = this.workForceEditForm.get('resourcePyramid')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.resourcePyramidList, 'display_name') : this.resourcePyramidList.slice())
      );

    this.filterOffShoreList = this.workForceEditForm.get('offShore')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.OffShoreList, 'display_name') : this.OffShoreList.slice())
      );

    this.filterContractList = this.workForceEditForm.get('contractType')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.contractList, 'display_name') : this.contractList.slice())
      );

    this.filterResourceClassificationList = this.workForceEditForm.get('resourceClassification')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((name: any) => name ? this._filterArray(name, this.resourceClassificationList, 'display_name') : this.resourceClassificationList.slice())
      );
    //---------------------------Section Five filterList---------------------------
    this.filterProjectIdList = this.workForceEditForm.get('projectId')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.project_name),
        map((name: any) => name ? this._filterArray(name, this.projectList, 'project_name') : this.projectList.slice())
      );
  }

  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    if (data?.display_name) {
      return data && data.display_name ? data.display_name : '';
    } else if (data?.name) {
      return data && data.name ? data.name : '';
    } else if (data?.project_name) {
      return data && data.project_name ? data.project_name : '';
    }
  }


  SelectedGdpValues(id: any) {
    this.gdpId = id.source.value.id;
  }
  navigateTo(id:any){
    this.router.navigate(['/workforce/edit/'+btoa(id)])
  }
}
