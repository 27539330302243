<section class="listPage">
    <div class="d-flex flex-column">
        <div class="list-head-filter-card">
            <mat-card class="border-17 p-0">
                <div class="d-flex flex-column">
                    <div class="p-3 border-bottom">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <h5 class="mb-0 fw-bold">Power BI Insights</h5>
                            <div class="d-flex flex-row justify-content-around">
                                <div class="p-1">
                                    <button mat-raised-button (click)="showAddInsights(MeasureofSuccessModalEdit)"
                                        class="uploadResourceList text-white text-capitalize cursor-pointer h-100"
                                        *ngxPermissionsOnly="['delivery.view_workforcedashboard']">
                                        <span class="d-flex justify-content-between align-items-center">
                                            <span class="p-1 fs-16">Add Insight</span>
                                        </span>
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                   
                </div>
            </mat-card>
        </div>
        <div class="list-body pt-4">
            <mat-card class="border-17">
                <div class="list-table-header pb-4">
                    <div class="row row-cols-2 pb-2 pt-2">
                        <div class="col-5">
                            <mat-card-title class="fs-16 mb-0 color-Port-Gore">
                                All Insights 
                                <!-- ({{recordsTotalCount}}) -->
                            </mat-card-title>
                        </div>
                        
                    </div>
                    
                </div>
                <mat-card-content>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                    scope="col"><span class="d-flex justify-content-center">S.No</span></th>
                                <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                    scope="col"><span
                                        class="d-flex justify-content-center">Insights
                                        </span></th>
                                <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                    scope="col"><span
                                        class="d-flex justify-content-center">Description</span>
                                </th>
                                <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                    scope="col"><span
                                        class="d-flex justify-content-center">Link</span>
                                </th>
                              
                                    <th class="fs-14 text-capitalize fw-500 text-white table-action-header border-end border-light"
                                        scope="col"><span
                                            class="d-flex justify-content-center">{{staticText.workforce_dashboard.actions}}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="cursor-pointer" *ngIf="insightsList?.length">
                                <tr *ngFor="let insight of insightsList;index as tableIndex">
                                    <th scope="row">
                                        <span class="d-flex justify-content-center fs-13">
                                            {{tableIndex+1}}
                                        </span>
                                    </th>
                                    <th scope="row" >
                                        <span class="d-flex justify-content-center">
                                            {{insight?.insight || '-'}}
                                        </span>
                                    </th>
                                    <td 
                                        >
                                        <span class="d-flex justify-content-center fw-bold color-blue">
                                            {{insight?.description || '-'}}
                                        </span>
                                    </td>
                                    <td >
                                        <span class="d-flex justify-content-center" style="color: blue;" (click)="OpenLink(insight?.report_link)">
                                            {{insight?.report_link || '-'}}
                                        </span>
                                    </td>
                                    
                                    
                                    <td>
                                        <div class="d-flex justify-content-around align-items-center">
                                            <!-- <img src="./assets/images/pages/workforce/edit.svg" alt="edit"
                                                *ngxPermissionsOnly="['delivery.add_workforcedashboard']"
                                                (click)="editWorkForceById(tableRecords?.id)"> -->
                                            <img src="./assets/images/comment-delete.svg" alt="delete"
                                                
                                                (click)="deletePopUp(MeasureofSuccessModalDelete,insight)">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <ng-container *ngIf="!insightsList?.length">
                                <div class="noRecords d-flex justify-content-center text-center">
                                    <span class="text-capitalize">
                                        {{staticText.common.no_records_found}}
                                    </span>
                                </div>
                            </ng-container>
                        </table>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <div class="d-flex  align-items-center" *ngIf="recordsTotalCount" [ngClass]="selctedLetterIndex==-1 ?'justify-content-between':'justify-content-end'">
                        <div class="p-1" *ngIf="selctedLetterIndex==-1">
                            <div
                                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark">
                                <span class="p-1">Displaying</span>
                                <select class="form-select form-select-sm table-sort-filter"
                                    aria-label="Default select example" [(ngModel)]="sortRecords"
                                    (ngModelChange)="getRecords()">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                </select>
                                <span class="p-1">{{recordsTotalCount}}</span>
                                <span class="p-1">records</span>
                            </div>
                        </div>
                        <div class="p-1" >
                            <ngb-pagination [pageSize]="sortRecords" [collectionSize]="recordsTotalCount" [maxSize]="6"
                                [rotate]="true" [(page)]="CurrentPage" [boundaryLinks]="true"
                                (pageChange)="getRecords();">
                            </ngb-pagination>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</section>

<ng-template #MeasureofSuccessModalEdit>

    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="'Add Insights'"> </app-modal-header-common>
            <div class="modal-body d-flex"> 
                <div class="row">
                  <div class="col-12">
                   
                    <mat-card class="m-1 p-1 d-grid">
                        

                        <div [formGroup]="krForm" class="row col-12 field-adjust p-2">
                          
                            <div>
                                <mat-form-field appearance="outline" class="col-4 pl-0">
                                    <mat-label>Insight</mat-label>
                                    <input matInput type="text" placeholder="Insight" formControlName="insight">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-4 pl-0">
                                    <mat-label>Description</mat-label>
                                    <input matInput type="text" placeholder="Description" formControlName="description">
                                </mat-form-field>

                               
                                <mat-form-field appearance="outline" class="col-4 pl-0">
                                    <mat-label>Link</mat-label>
                                    <input type="text" matInput fullWidth status="basic" placeholder="Link"
                                        formControlName="link">
                                </mat-form-field>
                            </div>
                            
                        </div>
                        <mat-card-actions class="border-top p-2 mt-2" align="end" >
                            <button class="saveOkr text-white text-capitalize float-right ml-2" (click)="validateAndSubmit()" mat-raised-button
                                >
                                {{staticText.buttons.save_btn}}
                            </button>
                            <button mat-button class="cancel" mat-dialog-close >
                                {{staticText.buttons.cancel_btn}}
                            </button>
                        </mat-card-actions>
                       
                    </mat-card>
                  </div>  
               
                
            </div>  
                    
            </div> 
            <!-- <div class="modal-footer">
                <div class="row">
                    <div>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" mat-dialog-close>{{staticText?.buttons.close_btn}}</button>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" (click)="updateKeyResultMoS(metricDetails)">{{staticText?.buttons.save_btn}}</button>

                    </div>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>

<ng-template #MeasureofSuccessModalDelete>

    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="'Delete Insight'"> </app-modal-header-common>

            <div class="modal-body d-flex"> 
                <div class="row">
                   
                        

                        <p>Do you really want to remove selected Insight?</p>
                        <mat-card-actions class="border-top p-2 mt-2" align="end" >
                            <button class="deleteOkr text-white text-capitalize float-right ml-2" (click)="deleteInsight()" mat-raised-button
                                >
                                Delete
                            </button>
                            <button mat-button class="cancel" mat-dialog-close >
                                {{staticText.buttons.cancel_btn}}
                            </button>
                        </mat-card-actions>
                       
               
                
            </div>  
                    
            </div> 
            <!-- <div class="modal-footer">
                <div class="row">
                    <div>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" mat-dialog-close>{{staticText?.buttons.close_btn}}</button>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" (click)="updateKeyResultMoS(metricDetails)">{{staticText?.buttons.save_btn}}</button>

                    </div>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>


