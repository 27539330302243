<section class="viewPage">
    <mat-card class="p-0 border-radius-15">
        <div class="bubble-top" style="background-color: #07B797;"></div>
        <div class="row p-4">
            <div class="col-3">
                <mat-card class="view-leftpane">
                    <div class="img-container">
                        <div class="bubble-top-left" style="background-color: #07B797;"></div>
                    </div>
                    <div class="d-flex flex-column trans-yaxis">
                        <div class="p-1 pb-3 pt-3 text-capitalize border-bottom">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <div class="mb-3">
                                    <ngx-avatar [name]="workForceData?.resource_name || 'NA'" bgColor="#F6F9FB"
                                        fgColor="#000000" size="65" [round]="true">
                                    </ngx-avatar>
                                </div>
                                <h5 class="mb-0 fs-20 color-Port-Gore">{{workForceData?.resource_name ||'NA'}}</h5>
                                <p class="fs-14 color-Lynch">{{workForceData?.job_title?.display_name ||'NA'}}</p>
                                <div class="d-flex flex-row align-items-center">
                                    <div class="p-1">
                                        <img src="./assets/images/pages/workforce/tick.svg" alt="tick">
                                    </div>
                                    <div class="p-1 d-flex align-items-center">
                                        <p class="fs-16 mb-0 color-Port-Gore oneline-sentence">
                                            {{workForceData?.vendor?.name ||'NA'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-1 pb-3 pt-3 d-flex align-items-center border-bottom">
                            <div class="d-flex flex-column">
                                <h5 class="fs-16 color-Port-Gore text-capitalize fw-normal">
                                    {{staticText.workforce_dashboard.director}}</h5>
                                <div class="d-flex flex-row justify-content-start align-items-center"
                                    *ngIf="workForceData?.director;">
                                    <div class="p-1 fw-bold">
                                        <ngx-avatar
                                            [name]="workForceData?.director?.first_name || workForceData?.director?.username || 'NA'"
                                            bgColor="#D40F78" fgColor="#FFFFFF" size="38" [round]="true">
                                        </ngx-avatar>
                                    </div>
                                    <div class="p-1 d-flex flex-column">
                                        <h5 class="fs-16 fw-500 text-capitalize mb-1 text-ellipse">
                                            {{workForceData?.director?.first_name || workForceData?.director?.username
                                            ||'NA'}}
                                        </h5>
                                        <!-- <p class="fs-14 mb-1 color-comet"> {{workForceData?.director?.first_name
                                            || 'NA'}}</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-1 pb-3 pt-3 d-flex align-items-center border-bottom">
                            <div class="d-flex flex-column">
                                <h5 class="fs-16 color-Port-Gore text-capitalize fw-normal">Location</h5>
                                <div class="d-flex flex-row justify-content-start align-items-start">
                                    <div class="p-1">
                                        <img src="./assets/images/pages/workforce/location.svg" alt="location"
                                            width="18">
                                    </div>
                                    <div class="p-1 d-flex flex-column">
                                        <p class="fs-16 mb-1">
                                            {{workForceData?.country?.display_name + ', ' + workForceData?.state?.name
                                            ||'NA'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-1 pb-3 pt-3 d-flex align-items-center">
                            <div class="d-flex flex-column">
                                <h5 class="fs-16 color-Port-Gore text-capitalize fw-normal">
                                    {{staticText.workforce_dashboard.gender}}</h5>
                                <p class="mb-0 fw-500 fs-16">{{workForceData?.gender?.display_name ||'NA'}}</p>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="col-9">
                <div class="view-centerPane" id="workForceEditform">
                    <form [formGroup]="workForceEditForm">
                        <div class="d-flex flex-column">
                            <div class="accordion" id="sectionContainer"
                                [ngClass]="{'scroll-container': showEditPageContent}">
                                <div class="pt-3">
                                    <div class="accordion-item sectionOne">
                                        <div class="accordion-header cursor-pointer pt-1 pb-1" id="sectionOne"
                                            data-bs-toggle="collapse" data-bs-target="#sectionOneCollapse"
                                            aria-expanded="true" aria-controls="sectionOneCollapse"
                                            [ngStyle]="{'pointer-events': showEditPageContent ? 'none' : 'auto'}"
                                            (click)="section1=!section1">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span
                                                    class="bubble-right text-white d-flex justify-content-center align-items-center fs-18 fw-500"
                                                    style="background-color: #232459;">
                                                    {{staticText.workforce_dashboard.section1}}
                                                </span>
                                                <div class="pe-4" *ngIf="!showEditPageContent">
                                                    <span *ngIf="section1">
                                                        <img src="./assets/images/pages/workforce/Expand_up.svg"
                                                            alt="up-arrow">
                                                    </span>
                                                    <span *ngIf="!section1">
                                                        <img src="./assets/images/pages/workforce/Expand_down.svg"
                                                            alt="down-arrow">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="sectionOneCollapse" class="accordion-collapse"
                                            aria-labelledby="sectionOne" data-bs-parent="#sectionContainer"
                                            [ngClass]="showEditPageContent ? 'show':'show collapse'">
                                            <div class="accordion-body d-flex align-items-center p-2">
                                                <ng-container *ngIf="!showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row row-cols-3 d-flex align-items-center">
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.contingent_worker_id}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.contingent_worker_id ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.ppr_no}}</h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.ppr_number ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.resrc_name}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.resource_name ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        Portfolio</h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.portfolio?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalizefw-normal">
                                                                        Subportfolio
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.sub_portfolio?.display_name||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        OU/GDP
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.vendor?.id ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5 class="col fs-16 color-Port-Gore fw-normal">
                                                                        {{staticText.workforce_dashboard.project_id_name}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        <span>{{workForceData?.project?.project_id
                                                                            ||'NA'}}</span>
                                                                        <span>&nbsp;&minus;&nbsp;</span>
                                                                        <span>{{workForceData?.project?.project_name||'NA'}}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row row-cols-3 d-flex align-items-center">
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Contingent Worker ID</mat-label>
                                                                    <input matInput placeholder="Contingent Worker ID"
                                                                        formControlName="contingentId">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>{{staticText.workforce_dashboard.ppr_no}}
                                                                    </mat-label>
                                                                    <input matInput
                                                                        placeholder="{{staticText.workforce_dashboard.ppr_no}}"
                                                                        formControlName="pprNumber" type="number">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.resrc_name}}
                                                                    </mat-label>
                                                                    <input matInput
                                                                        placeholder="{{staticText.workforce_dashboard.resrc_name}}"
                                                                        formControlName="resourceName">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>{{staticText.filter.portfolio}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="Portfolio" type="text"
                                                                        formControlName="Portfolio">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #Portfolio="matAutocomplete"
                                                                        [displayWith]="displayFn"
                                                                        (optionSelected)="SelectedPortFolioValues($event)">
                                                                        <mat-option
                                                                            *ngFor="let option of filterPortfolioList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>{{staticText.filter.sub_portfolio}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="subPortfolio" type="text"
                                                                        formControlName="subPortfolio">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #subPortfolio="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterSubPortfolioList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>{{staticText.filter.gdp}}</mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="gdp" type="text"
                                                                        formControlName="gdp">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #gdp="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterGdpList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.project_name}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="projectId" type="text"
                                                                        formControlName="projectId">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #projectId="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterProjectIdList | async"
                                                                            [value]="option">
                                                                            {{option.project_name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <div class="accordion-item sectionTwo">
                                        <div class="accordion-header cursor-pointer pt-1 pb-1" id="sectionTwo"
                                            data-bs-toggle="collapse" data-bs-target="#sectionTwoCollapse"
                                            aria-expanded="true" aria-controls="sectionTwoCollapse"
                                            [ngStyle]="{'pointer-events': showEditPageContent ? 'none' : 'auto'}"
                                            (click)="section2=!section2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span
                                                    class="bubble-right text-white d-flex justify-content-center align-items-center fs-18 fw-500"
                                                    style="background-color: #07B797;">
                                                    {{staticText.workforce_dashboard.section2}}
                                                </span>
                                                <div class="pe-4" *ngIf="!showEditPageContent">
                                                    <span *ngIf="section2">
                                                        <img src="./assets/images/pages/workforce/Expand_up.svg"
                                                            alt="up-arrow">
                                                    </span>
                                                    <span *ngIf="!section2">
                                                        <img src="./assets/images/pages/workforce/Expand_down.svg"
                                                            alt="down-arrow">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="sectionTwoCollapse" class="accordion-collapse"
                                            aria-labelledby="sectionTwo" data-bs-parent="#sectionContainer"
                                            [ngClass]="showEditPageContent ? 'show':'collapse'">
                                            <div class="accordion-body d-flex align-items-center p-2">
                                                <ng-container *ngIf="!showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row row-cols-3 d-flex align-items-center">
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.state}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.state?.display_name ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.country}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.country?.display_name ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.job_title}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.job_title?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.job_category}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.job_category?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.resrc_pyramid}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.resource_pyramid?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.years_exp}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.no_of_experience ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        Offshore Leverage
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.offshore_leverage?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.contract_type}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.contract_type?.display_name||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.resrc_classification}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.resource_classification?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row row-cols-3 d-flex align-items-center">
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.country}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="country" type="text"
                                                                        formControlName="country">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #country="matAutocomplete"
                                                                        [displayWith]="displayFn"
                                                                        (optionSelected)="SelectedCountryValues($event)">
                                                                        <mat-option
                                                                            *ngFor="let option of filterCountryList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>{{staticText.workforce_dashboard.state}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="state" type="text"
                                                                        formControlName="state">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #state="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterStateList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>Job Title</mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="jobTitle" type="text"
                                                                        formControlName="jobTitle">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #jobTitle="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterJobTitleList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.job_category}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="jobCategory" type="text"
                                                                        formControlName="jobCategory">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #jobCategory="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterJobCategoryList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.resrc_pyramid}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="resourcePyramid" type="text"
                                                                        formControlName="resourcePyramid">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #resourcePyramid="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterResourcePyramidList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>No. Of Years Of Experience</mat-label>
                                                                    <input matInput placeholder="years Of Exp"
                                                                        formControlName="yearsExp" type="number">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.offshore_leverage}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="offShore" type="text"
                                                                        formControlName="offShore">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #offShore="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterOffShoreList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.contract_type}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="contractType" type="text"
                                                                        formControlName="contractType">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete #contractType="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterContractList | async"
                                                                            [value]="option">
                                                                            {{option.display_name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2 autocomplete">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <img class="img-fluid" matPrefix
                                                                        src="/assets/images/balancescore/balanceheader/filter.svg"
                                                                        alt="filter">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.resrc_classification}}
                                                                    </mat-label>
                                                                    <input class="text-ellipse" matInput
                                                                        [matAutocomplete]="resourceClassification"
                                                                        type="text"
                                                                        formControlName="resourceClassification">
                                                                    <img class="img-fluid" matSuffix
                                                                        src="/assets/images/balancescore/balanceheader/search.svg"
                                                                        alt="filter">
                                                                    <mat-autocomplete
                                                                        #resourceClassification="matAutocomplete"
                                                                        [displayWith]="displayFn">
                                                                        <mat-option
                                                                            *ngFor="let option of filterResourceClassificationList | async"
                                                                            [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <div class="accordion-item sectionThree">
                                        <div class="accordion-header cursor-pointer pt-1 pb-1" id="sectionThree"
                                            data-bs-toggle="collapse" data-bs-target="#sectionThreeCollapse"
                                            aria-expanded="true" aria-controls="sectionThreeCollapse"
                                            [ngStyle]="{'pointer-events': showEditPageContent ? 'none' : 'auto'}"
                                            (click)="section3=!section3">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span
                                                    class="bubble-right text-white d-flex justify-content-center align-items-center fs-18 fw-500"
                                                    style="background-color: #FF6F00;">
                                                    {{staticText.workforce_dashboard.section3}}
                                                </span>
                                                <div class="pe-4" *ngIf="!showEditPageContent">
                                                    <span *ngIf="section3">
                                                        <img src="./assets/images/pages/workforce/Expand_up.svg"
                                                            alt="up-arrow">
                                                    </span>
                                                    <span *ngIf="!section3">
                                                        <img src="./assets/images/pages/workforce/Expand_down.svg"
                                                            alt="down-arrow">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="sectionThreeCollapse" class="accordion-collapse"
                                            aria-labelledby="sectionThree" data-bs-parent="#sectionContainer"
                                            [ngClass]="showEditPageContent ? 'show':'collapse'">
                                            <div class="accordion-body d-flex align-items-center p-2">
                                                <ng-container *ngIf="!showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row row-cols-3 d-flex align-items-center">
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.start_date}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.start_date | date:'d MMM y'
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.visa_exp_date}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.visa_expiry_date | date:'d MMM
                                                                        y'
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.visa_app_date}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.new_visa_application_date |
                                                                        date:'d
                                                                        MMM y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.prod_access_enddt}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.prod_access_end_date | date:'d
                                                                        MMM
                                                                        y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.pii_access_enddt}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.pii_access_end_date | date:'d
                                                                        MMM
                                                                        y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.pci_access_enddt}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.pci_access_end_date | date:'d
                                                                        MMM
                                                                        y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.cross_enddt}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.cross_border_data_end_date |
                                                                        date:'d MMM y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.generic_enddt}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.generic_id_end_date | date:'d
                                                                        MMM
                                                                        y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.gdpr_enddt}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.gdpr_compliance_end_date |
                                                                        date:'d
                                                                        MMM y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.completion_date}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.date_of_completion_of_security
                                                                        |
                                                                        date:'d MMM y' ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col pb-4">
                                                                <div
                                                                    class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                                    <h5
                                                                        class="col mb-1 fs-16 color-Port-Gore text-capitalize fw-normal">
                                                                        {{staticText.workforce_dashboard.zfp_foundation_status}}
                                                                    </h5>
                                                                    <p class="col mb-0 fs-16 fw-500">
                                                                        {{workForceData?.zfp_foundation_status?.display_name
                                                                        ||'NA'}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row row-cols-3 d-flex align-items-center">
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.start_date}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="startDate"
                                                                        formControlName="startDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="startDate.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="startDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #startDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.visa_exp_date}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="visaExpiryDate"
                                                                        formControlName="visaExpiryDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="visaExpiryDate.open()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="visaExpiryDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #visaExpiryDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.visa_app_date}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="newVisa"
                                                                        formControlName="newVisa"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="newVisa.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="newVisa">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #newVisa>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.prod_access_enddt}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="productionEndDate"
                                                                        formControlName="productionEndDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="productionEndDate.open()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="productionEndDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #productionEndDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.pii_access_enddt}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="pIIEndDate"
                                                                        formControlName="pIIEndDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="pIIEndDate.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="pIIEndDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #pIIEndDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.pci_access_enddt}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="pciEndDate"
                                                                        formControlName="pciEndDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="pciEndDate.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="pciEndDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #pciEndDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.cross_enddt}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="crossBorderEndDate"
                                                                        formControlName="crossBorderEndDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="crossBorderEndDate.open()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="crossBorderEndDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #crossBorderEndDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.generic_enddt}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="genericEndDate"
                                                                        formControlName="genericEndDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="genericEndDate.open()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="genericEndDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #genericEndDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.gdpr_enddt}}
                                                                    </mat-label>
                                                                    <input matInput
                                                                        [matDatepicker]="gdprComplianceEndDate"
                                                                        formControlName="gdprComplianceEndDate"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="gdprComplianceEndDate.open()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="gdprComplianceEndDate">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #gdprComplianceEndDate>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.completion_date}}
                                                                    </mat-label>
                                                                    <input matInput [matDatepicker]="securityTraining"
                                                                        formControlName="securityTraining"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        (click)="securityTraining.open()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="securityTraining">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #securityTraining>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.zfp_foundation_status}}
                                                                    </mat-label>
                                                                    <mat-select formControlName="foundationStatus">
                                                                        <mat-option
                                                                            *ngFor="let option of foundationList"
                                                                            [value]="option.id">
                                                                            {{option.display_name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <div class="accordion-item sectionFour">
                                        <div class="accordion-header cursor-pointer pt-1 pb-1" id="sectionFour"
                                            data-bs-toggle="collapse" data-bs-target="#sectionFourCollapse"
                                            aria-expanded="true" aria-controls="sectionFourCollapse"
                                            [ngStyle]="{'pointer-events': showEditPageContent ? 'none' : 'auto'}"
                                            (click)="section4=!section4">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span
                                                    class="bubble-right text-white d-flex justify-content-center align-items-center fs-18 fw-500"
                                                    style="background-color: #D40F78;">
                                                    {{staticText.workforce_dashboard.section4}}
                                                </span>
                                                <div class="pe-4" *ngIf="!showEditPageContent">
                                                    <span *ngIf="section4">
                                                        <img src="./assets/images/pages/workforce/Expand_up.svg"
                                                            alt="up-arrow">
                                                    </span>
                                                    <span *ngIf="!section4">
                                                        <img src="./assets/images/pages/workforce/Expand_down.svg"
                                                            alt="down-arrow">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="sectionFourCollapse" class="accordion-collapse"
                                            aria-labelledby="sectionFour" data-bs-parent="#sectionContainer"
                                            [ngClass]="showEditPageContent ? 'show':'collapse'">
                                            <div class="accordion-body d-flex align-items-center p-2">
                                                <ng-container *ngIf="!showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div
                                                            class="row row-cols-1 d-flex justify-content-start align-items-center">
                                                            <h5 class="col mb-3 fs-16 color-Port-Gore fw-normal">
                                                                {{staticText.workforce_dashboard.cont_plan}}</h5>
                                                            <p class="col mb-0 fs-16 fw-500">
                                                                {{workForceData?.continuance_plan || 'NA'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="showEditPageContent">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <div class="col p-2">
                                                                <mat-form-field class="example-full-width w-100"
                                                                    appearance="outline">
                                                                    <mat-label>
                                                                        {{staticText.workforce_dashboard.cont_plan}}
                                                                    </mat-label>
                                                                    <textarea cdkTextareaAutosize matInput
                                                                        formControlName="continuancePlan"
                                                                        placeholder="Write Plan here..."></textarea>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end pt-3 action-buttons">
                                <ng-container *ngIf="!showEditPageContent">
                                    <div class="d-flex flex-row justify-content-around">
                                        <div class="p-1">
                                            <button mat-raised-button (click)="navigateTo(workForceId)"
                                                class="viewPageButton text-white text-capitalize cursor-pointer"
                                                *ngxPermissionsOnly="['delivery.view_workforcedashboard']">
                                                {{staticText.buttons.edit_btn}}
                                            </button>
                                        </div>
                                        <div class="p-1">
                                            <button mat-raised-button [routerLink]="['/workforce/dashboard']"
                                                class="closeButton text-white text-capitalize cursor-pointer">
                                                {{staticText.buttons.close_btn}}</button>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="showEditPageContent">
                                    <div class="d-flex flex-row justify-content-around align-items-center">
                                        <div class="p-1">
                                            <span class="editPageButton text-capitalize cursor-pointer pe-2"
                                                [routerLink]="['/workforce/dashboard']">
                                                {{staticText.buttons.cancel_btn}}
                                            </span>
                                        </div>
                                        <div class="p-1">
                                            <button mat-raised-button
                                                class="submitButton text-white text-capitalize cursor-pointer"
                                                *ngxPermissionsOnly="['delivery.add_workforcedashboard']"
                                                (click)="submitForm()">
                                                {{staticText.buttons.submit_btn}}
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-card>
</section>